var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.showLieferstellen,
            callback: function ($$v) {
              _vm.showLieferstellen = $$v
            },
            expression: "showLieferstellen",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { outlined: "" } },
            [
              _c("v-card-title", [_vm._v("Lieferstellen")]),
              _c(
                "v-card-text",
                [
                  _c("v-simple-table", {
                    attrs: { "fixed-header": "", dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("#"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Marktlokation"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Straße und Haus-Nr."),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("PLZ"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Ort"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.$store.state.contract.aktuellerVertrag[
                                  "marktlokation"
                                ],
                                function (marktlokation, index) {
                                  return _c(
                                    "tr",
                                    { key: marktlokation.nummer },
                                    [
                                      _c("td", [
                                        _vm._v(" " + _vm._s(index + 1)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(marktlokation.nummer)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(marktlokation.strasse) +
                                            " " +
                                            _vm._s(marktlokation.hausnummer) +
                                            _vm._s(
                                              marktlokation.hausnummer_zusatz
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (marktlokation.plz + "").padStart(
                                                5,
                                                "0"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(marktlokation.ort) + " "
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green-darken-1", variant: "text" },
                      on: {
                        click: function ($event) {
                          _vm.showLieferstellen = false
                        },
                      },
                    },
                    [_vm._v(" Schließen ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bestellungAusfuehren", {
        attrs: {
          active: _vm.dialog.bestellungAusfuehren,
          orderdata: this.orderdata,
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { outlined: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { "three-line": "" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("div", { staticClass: "text-overline mb-4" }, [
                            _vm._v(_vm._s(_vm.getCurrentFirma.id)),
                          ]),
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _vm.isFirmenArray
                                ? _c("v-select", {
                                    attrs: {
                                      "item-text": "name",
                                      items: _vm.getFirmenList,
                                      value: _vm.getCurrentFirma,
                                      "return-object": "",
                                    },
                                    on: { change: _vm.setCurrentFirma },
                                  })
                                : _c("div", [
                                    _vm._v(_vm._s(_vm.getCurrentFirma.name)),
                                  ]),
                            ],
                            1
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getCurrentFirma.strasse) +
                                " " +
                                _vm._s(_vm.getCurrentFirma.hausnummer) +
                                _vm._s(_vm.getCurrentFirma.hausnummer_zusatz)
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.getCurrentFirma.plz + "").padStart(
                                    5,
                                    "0"
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.getCurrentFirma.ort) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { outlined: "" } },
                [
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsnummer")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items:
                                          _vm.$store.state.contract
                                            .aktuelleVertragsnamen,
                                        "item-text": "Vertrag",
                                        "single-line": "",
                                      },
                                      model: {
                                        value:
                                          _vm.$store.state.contract
                                            .aktuellerVertragsname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$store.state.contract,
                                            "aktuellerVertragsname",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$store.state.contract.aktuellerVertragsname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsart")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.contract
                                          .aktuellerVertrag.vertragsart
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Energieart")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.contract
                                          .aktuellerVertrag.commodity
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsbeginn")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.contract
                                          .aktuellerVertrag.vertragsbeginn
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Vertragsende")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.contract
                                          .aktuellerVertrag.vertragsende
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Anzahl Lieferstellen")]),
                                _c(
                                  "td",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$store.state.contract
                                            .aktuellerVertrag
                                            .anzahlMarktlokationen
                                        ) +
                                        " ("
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { plain: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showLieferstellen =
                                              !_vm.showLieferstellen
                                          },
                                        },
                                      },
                                      [
                                        _vm.showLieferstellen == false
                                          ? _c("span", [_vm._v("Anzeigen")])
                                          : _vm._e(),
                                        _vm.showLieferstellen == true
                                          ? _c("span", [_vm._v("Verbergen")])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(") "),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                {
                  key: _vm.componentKey,
                  staticClass: "mx-auto",
                  attrs: { outlined: "" },
                },
                [
                  _c("v-card-title", [_vm._v("Aktuelle Notierungen")]),
                  _c("v-simple-table", {
                    attrs: { "fixed-header": "", dense: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v("Base"),
                                  _c("sub", [_vm._v("NEW")]),
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.$store.state.contract
                                          .aktuelleTranche.Produkte.basetext
                                      ) +
                                      ") "
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      parseFloat(
                                        _vm.$store.state.contract
                                          .aktuelleTranche.Preise.base.price
                                      ).toLocaleString("de-DE", {
                                        maximumFractionDigits: 2,
                                      })
                                    ) + " €/MWh"
                                  ),
                                ]),
                              ]),
                              _vm.$store.state.contract.aktuelleTranche.Produkte
                                .peak
                                ? _c("tr", [
                                    _c("td", [
                                      _vm._v("Peak"),
                                      _c("sub", [_vm._v("NEW")]),
                                      _vm._v(
                                        " (" +
                                          _vm._s(
                                            _vm.$store.state.contract
                                              .aktuelleTranche.Produkte.peaktext
                                          ) +
                                          ") "
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          parseFloat(
                                            _vm.$store.state.contract
                                              .aktuelleTranche.Preise.peak.price
                                          ).toLocaleString("de-DE", {
                                            maximumFractionDigits: 2,
                                          })
                                        ) + " €/MWh"
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _vm.readonly == true
                ? _c(
                    "v-card",
                    {
                      key: _vm.stopplossKey,
                      staticClass: "mx-auto",
                      attrs: { outlined: "" },
                    },
                    [
                      _c("v-card-title", [
                        _vm._v("Aktuelle Limits (Base"),
                        _c("sub", [_vm._v("NEW")]),
                        _vm._v(")"),
                      ]),
                      _c("v-simple-table", {
                        attrs: { "fixed-header": "", dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.$store.state.contract
                                        .aktuellerStoppLoss,
                                      function (sl) {
                                        return _c("tr", { key: sl.name }, [
                                          _c("td", [_vm._v(_vm._s(sl.name))]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                sl.limit.toLocaleString(
                                                  "de-DE",
                                                  { maximumFractionDigits: 2 }
                                                )
                                              ) + " €/MWh"
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1243179070
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                {
                  key: _vm.componentKey,
                  staticClass: "mx-auto",
                  attrs: { outlined: "" },
                },
                [
                  _c("v-card-title", [_vm._v("Tranchen: ")]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-simple-table", {
                            attrs: { dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("tbody", [
                                      _vm.$store.state.contract
                                        .aktuelleTranchenzeitraeume.length > 1
                                        ? _c("tr", [
                                            _c("td", [_vm._v("Zeitraum")]),
                                            _c(
                                              "td",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      _vm.$store.state.contract
                                                        .aktuelleTranchenzeitraeume,
                                                    "item-text": "Vertrag",
                                                    "single-line": "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.$store.state.contract
                                                        .aktuellerTranchenzeitraum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.$store.state
                                                          .contract,
                                                        "aktuellerTranchenzeitraum",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "$store.state.contract.aktuellerTranchenzeitraum\n                                                ",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("tr", [
                                        _c("td", [_vm._v("Laufzeit von")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .aktuelleTranche.Laufzeitvon
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [_vm._v("Laufzeit bis")]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .aktuelleTranche.Laufzeitbis
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v("Einzeltranche in kWh"),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                Math.round(
                                                  _vm.germanNumberStringToNumber(
                                                    _vm.$store.state.contract
                                                      .aktuelleTranche
                                                      .TrancheMengeinkWh
                                                  )
                                                ).toLocaleString("de-DE")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            " aktueller Preis Einzeltranche pro MWh (" +
                                              _vm._s(_vm.priceDate) +
                                              ") "
                                          ),
                                        ]),
                                        _vm.$store.state.contract
                                          .aktuellerVertrag.commodity ===
                                        "STROM"
                                          ? _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$store.state.contract
                                                      .aktuelleTranche
                                                      .FaktorBase
                                                  ) +
                                                  " x " +
                                                  _vm._s(
                                                    parseFloat(
                                                      _vm.$store.state.contract
                                                        .aktuelleTranche.Preise
                                                        .base.price
                                                    ).toLocaleString("de-DE", {
                                                      maximumFractionDigits: 2,
                                                    })
                                                  ) +
                                                  " €/MWh + " +
                                                  _vm._s(
                                                    _vm.$store.state.contract
                                                      .aktuelleTranche
                                                      .FaktorPeak
                                                  ) +
                                                  " x " +
                                                  _vm._s(
                                                    parseFloat(
                                                      _vm.$store.state.contract
                                                        .aktuelleTranche.Preise
                                                        .peak.price
                                                    ).toLocaleString("de-DE", {
                                                      maximumFractionDigits: 2,
                                                    })
                                                  ) +
                                                  " €/MWh + " +
                                                  _vm._s(
                                                    _vm.$store.state.contract.aktuelleTranche.Strukturkonstante.toLocaleString(
                                                      "de-DE",
                                                      {
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                  ) +
                                                  " €/MWh = " +
                                                  _vm._s(
                                                    _vm.$store.state.contract.aktuelleTranche.Endpreis.price.toLocaleString(
                                                      "de-DE",
                                                      {
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                  ) +
                                                  " €/MWh "
                                              ),
                                            ])
                                          : _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$store.state.contract
                                                      .aktuelleTranche
                                                      .FaktorBase
                                                  ) +
                                                  " x " +
                                                  _vm._s(
                                                    parseFloat(
                                                      _vm.$store.state.contract
                                                        .aktuelleTranche.Preise
                                                        .base.price
                                                    ).toLocaleString("de-DE", {
                                                      maximumFractionDigits: 2,
                                                    })
                                                  ) +
                                                  " €/MWh + " +
                                                  _vm._s(
                                                    _vm.$store.state.contract.aktuelleTranche[
                                                      "Strukturkonstante"
                                                    ].toLocaleString("de-DE", {
                                                      maximumFractionDigits: 2,
                                                    })
                                                  ) +
                                                  " € = " +
                                                  _vm._s(
                                                    _vm.$store.state.contract.aktuelleTranche.Endpreis.price.toLocaleString(
                                                      "de-DE",
                                                      {
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                  ) +
                                                  " €/MWh "
                                              ),
                                            ]),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c("v-simple-table", {
                            attrs: { "fixed-header": "", dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Tranche")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [
                                            _vm._v("Energievolumen (kWh)"),
                                            _c("br"),
                                            _c(
                                              "span",
                                              { staticClass: "font-italic" },
                                              [_vm._v("gerundet auf kWh")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Kosten der Tranche (€)")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Preis (€/MWh)")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-2",
                                          },
                                          [_vm._v("Bestellstatus")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.sortedTranchen,
                                        function (tranchennummer) {
                                          return _c(
                                            "tr",
                                            { key: tranchennummer },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(tranchennummer) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        _vm.germanNumberStringToNumber(
                                                          _vm.$store.state
                                                            .contract
                                                            .aktuelleTranche
                                                            .TrancheMengeinkWh
                                                        )
                                                      ).toLocaleString("de-DE")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                !_vm.$store.state.contract.geschlosseneTranchenNamen.includes(
                                                  tranchennummer
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$store.state.contract.aktuelleTranche[
                                                              "cost"
                                                            ].toLocaleString(
                                                              "de-DE",
                                                              {
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$store.state.contract.geschlosseneTranchen[
                                                              tranchennummer
                                                            ].cost.toLocaleString(
                                                              "de-DE",
                                                              {
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                              ]),
                                              _c("td", [
                                                !_vm.$store.state.contract.geschlosseneTranchenNamen.includes(
                                                  tranchennummer
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$store.state.contract.aktuelleTranche[
                                                              "Endpreis"
                                                            ][
                                                              "price"
                                                            ].toLocaleString(
                                                              "de-DE",
                                                              {
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )
                                                          )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$store.state.contract.geschlosseneTranchen[
                                                              tranchennummer
                                                            ].pricing.price.toLocaleString(
                                                              "de-DE",
                                                              {
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                              ]),
                                              _c("td", [
                                                !_vm.$store.state.contract.geschlosseneTranchenNamen.includes(
                                                  tranchennummer
                                                )
                                                  ? _c("span", [
                                                      _vm.$store.state.contract
                                                        .naechsterOffenerTranchenName ===
                                                        tranchennummer &&
                                                      !_vm.readonly &&
                                                      parseInt(
                                                        _vm.$store.state
                                                          .contract
                                                          .aktuelleTranche
                                                          .TrancheMengeinkWh
                                                      ) > 0
                                                        ? _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setOrder(
                                                                      tranchennummer
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Jetzt bestellen"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " Bestellt am " +
                                                          _vm._s(
                                                            _vm.getDateFromTS(
                                                              _vm.$store.state
                                                                .contract
                                                                .geschlosseneTranchen[
                                                                tranchennummer
                                                              ].orderdatetime
                                                            )
                                                          )
                                                      ),
                                                    ]),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c("tfoot", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [_vm._v("Summe")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .TrachenSummen.volume
                                              ) +
                                                " / " +
                                                _vm._s(
                                                  _vm.$store.state.contract
                                                    .TrachenSummen
                                                    .volumeAllTranches
                                                ) +
                                                " kWh"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .TrachenSummen.cost
                                              ) + "€ Kosten bisher "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .TrachenSummen.price
                                              ) + " mittlerer Preis (€/MWh)"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-left grey lighten-4",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$store.state.contract
                                                  .TrachenSummen.numberClosed
                                              ) +
                                                " / " +
                                                _vm._s(
                                                  _vm.$store.state.contract
                                                    .TrachenSummen
                                                    .numberAllTranches
                                                ) +
                                                " Tranchen geschlossen"
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12", md: "6" } }),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "v-row",
            { staticClass: "text-center" },
            [
              _c(
                "v-col",
                [
                  _c("loading", {
                    attrs: {
                      active: _vm.isLoading,
                      color: _vm.loading.color,
                      "can-cancel": _vm.loading.canCancel,
                      "is-full-page": _vm.loading.isFullPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.chartData.datasets != []
                    ? _c(
                        "v-card",
                        { staticClass: "mx-auto", attrs: { outlined: "" } },
                        [
                          _c("v-card-title", [_vm._v("Marktpreisentwicklung")]),
                          _c("evchart", {
                            attrs: {
                              chartOptions: _vm.chartOptions,
                              chartData: _vm.chartData,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [_vm._v("StoppLoss Limits")]),
                      _c("evchart", {
                        attrs: {
                          chartOptions: _vm.detailChartOptions,
                          chartData: _vm.detailChartData,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }