<template>
  <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
    :height="height" />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeSeriesScale,
  TimeScale,
  PointElement
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";


ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeSeriesScale,
  TimeScale,
  PointElement,
  annotationPlugin
)

export default {
  name: "evchart",
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => { }
    }
    
  },
  data() {
    return {
   
    }
  },
}
</script>
