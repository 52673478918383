var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "750px" },
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading, disabled: _vm.isLoading } },
        [
          _c("v-card-title", [
            _vm._v(
              "Bestellung ausführen für " + _vm._s(_vm.orderdata.tranchennummer)
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-text-field", {
                    attrs: { label: "Besteller", readonly: "" },
                    model: {
                      value: _vm.orderdata.orderdaten.orderedbyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderdata.orderdaten, "orderedbyName", $$v)
                      },
                      expression: "orderdata.orderdaten.orderedbyName",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: { label: "Preis pro MWh", readonly: "" },
                    model: {
                      value: _vm.getPrice,
                      callback: function ($$v) {
                        _vm.getPrice = $$v
                      },
                      expression: "getPrice",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: { label: "Kosten der Tranche in €", readonly: "" },
                    model: {
                      value: _vm.getCost,
                      callback: function ($$v) {
                        _vm.getCost = $$v
                      },
                      expression: "getCost",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: { label: "Volumen in kWh", readonly: "" },
                    model: {
                      value: _vm.getVolume,
                      callback: function ($$v) {
                        _vm.getVolume = $$v
                      },
                      expression: "getVolume",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primaryLight",
                    text: "",
                    value: "BESTELLUNGABBRUCH",
                  },
                  on: { click: _vm.cancleOrder },
                },
                [_vm._v(" Abbrechen ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primaryLight", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.order()
                    },
                  },
                },
                [_vm._v(" Bestellen ")]
              ),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }