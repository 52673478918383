<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title
        >Bestellung ausführen für {{ orderdata.tranchennummer }}</v-card-title
      >
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="orderdata.orderdaten.orderedbyName"
            label="Besteller"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="getPrice"
            label="Preis pro MWh"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="getCost"
            label="Kosten der Tranche in €"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="getVolume"
            label="Volumen in kWh"
            readonly
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="cancleOrder"
          value="BESTELLUNGABBRUCH"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="order()"> Bestellen </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { functions } from "@/plugins/firebase"
import { httpsCallable } from "firebase/functions"

export default {
    data() {
        return {
            isLoading: false,
            dialogVisible: false,
            userdata: {}
        }
    },

    computed: {
        getPrice() {
            return this.orderdata.orderdaten.pricing.price.toLocaleString("de-DE")
        },
        getCost() {
            return this.orderdata.orderdaten.cost.toLocaleString("de-DE")
        },
        getVolume() {
            return this.orderdata.orderdaten.volume.toLocaleString("de-DE")
        }
    },

    watch: {
        active() {
            this.dialogVisible = true
        }
    },

    methods: {
        async order() {
            const orderTranche = httpsCallable(functions, "orderTranche")
            this.loading = true
            console.log("order",this.orderdata);
            try {
                const { data } = await orderTranche(this.orderdata)
                switch (data.code) {
                    case -1:
                        this.$store.commit(
                            "showSnackbar",
                            {
                                message: data.msg,
                                status: "error"
                            },
                            { root: true }
                        )
                        this.dialogVisible = false
                        break
                    case 0:
                        this.$store.commit(
                            "showSnackbar",
                            {
                                message: data.msg,
                                status: "success"
                            },
                            { root: true }
                        )
                        this.$store.state.contract.reloadOrderview=true

                        this.dialogVisible = false
                        break
                    default:
                        break
                }
            } catch (err) {
                this.$store.commit(
                    "showSnackbar",
                    {
                        message: "Es ist ein Fehler aufgetreten bei dem Versuch die Bestellung abzusenden.",
                        status: "error"
                    },
                    { root: true }
                )
                console.error(err)
            }
            this.loading = false
        },
        cancleOrder() {
            this.docid = ""
            this.docs = []
            this.dialogVisible = false
        }
    },

    created() {},

    props: {
        active: Number,
        orderdata: {
            type: Object,
            default: function () {
                return {
                    orderdaten: {
                        orderedbyName: ""
                    }
                }
            }
        }
    }
}
</script>
    